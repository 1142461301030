.radio-after::after {
	@apply absolute;
	@apply transition-all;
	@apply delay-75;
	@apply duration-300;
	@apply block;
	@apply opacity-0;
	@apply w-3;
	@apply h-3;
	@apply bg-white;
	@apply rounded-full;

	content: '';
	transform: scale(0.3);
}

.radio-after:checked::after {
	@apply opacity-100;
	@apply scale-100;
}