/* TODO: move this animation to tailwind config */
.c--skeleton {
	animation-duration: .6s;
	animation-name: blinkFade;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

@keyframes blinkFade {
	from {
		opacity: 1;
	}
	to {
		opacity: .4;
	}
}
