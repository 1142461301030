@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.ra-input-metas, .ra-input-products, .ra-input-images {
	@apply !pt-4;
	@apply !my-10;
}

.ra-input-metas > .MuiFormLabel-root,
.ra-input-products > .MuiFormLabel-root {
	transform: none !important;
}
