span.icon {
	display: inline-flex;
}

span.icon {
	height: 1rem;
}

span.icon svg {
	height: 100%;
}

span.icon .fill {
	fill: currentColor;
}

span.icon .stroke {
	fill: none;
	stroke: currentColor;
}